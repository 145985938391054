@import "../module/stooges-variables";
@import "../module/stooges-mixin";
@import "./project-mixin";
@import "./project-variables";
::selection {
  background: $color-main;
  color: #000;
}

[class*="flaticon-"]:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.flaticon-drop-down-arrow:before {
  content: "\f100";
}
.flaticon-phone:before {
  content: "\f101";
}
.flaticon-home:before {
  content: "\f102";
}
.flaticon-opened-email-envelope:before {
  content: "\f103";
}
.flaticon-menu:before {
  content: "\f104";
}
.flaticon-list:before {
  content: "\f105";
}
.flaticon-chevron-arrow-up:before {
  content: "\f106";
}
.flaticon-placeholder:before {
  content: "\f107";
}
.flaticon-menu-1:before {
  content: "\f108";
}
.flaticon-chevron-arrow-down:before {
  content: "\f109";
}
.flaticon-cancel:before {
  content: "\f10a";
}
.flaticon-musica-searcher:before {
  content: "\f10b";
}
.flaticon-right-chevron:before {
  content: "\f10c";
}
.flaticon-fax:before {
  content: "\f10d";
}
.flaticon-customer-service-agent:before {
  content: "\f10e";
}
.flaticon-service:before {
  content: "\f10f";
}
.flaticon-file:before {
  content: "\f110";
}
