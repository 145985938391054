﻿@import '../../src/module/stooges-variables';
@import '../../src/scss/project-variables';
@import '../../src/module/stooges-mixin';
@import '../../src/scss/project-mixin';
@import '../../src/module/stooges-basscss';
@import '../../src/scss/project-basscss';
@import '../../src/scss/font-family';

@keyframes goyang {
    from {
        top: -2px;
    }

    to {
        top: 2px;
    }
}

* {
    @extend %scrollBarProperties;
}

header {
    @extend %header;
}

.homeBanner {
    @extend %singleImageCarousel;
}

.categoryButtons {
    @extend %carouselWithRoundedItemsWithinContainer;
}

.categoryBanner {
    @extend %threeImageInARowFullWidth;
}

.productsDisplay {
    @extend %fourItemsDisplayWithTabsWithinContainer;
}

.brandingDisplay {
    @extend %imageCarouselWithinContainer;
}

.returnBox {
    @extend %floatingButton;
}

footer {
    @extend %footer;
}


.privacy {
    @extend %displayParagraphWithTitleWithinContainer;
}

.directoryBar {
    @extend %horizontalWordDisplayWithArrowWithinContainer;
}

.productsContainer {
    @extend %threeItemsDisplayInARowWithCarouselAndSidebarWithinContainer;
}

.singleProductView {
    @extend %viewItemsImageInCarouselWithItemDetailAndCarouselAndSidebarWithinContainer;
}

.aboutUs {
    @extend %imageWithParagraphInARowWithinContainer;
}

.aboutPointForm {
    @extend %threePointsWithDescriptionAndIconWithinContainer;
}

.contactMap {
    @extend %displayAnIFrameWithBorderWithinContainer;
}

.contactUs {
    @extend %contactInfoWithEnquiryFormInARowWithinContainer;
}

.allServiceWrapper {
    @extend %displayParagraphWithTitleWithinContainer;
}
