//app 规范
//1 color
$color-main: lighten(#8b002a, 5); //主题颜色
$color-secondary-1: #696969;
$color-secondary-2: #eeeeee;
$color-secondary-3: #dddddd;
$color-basic-1: #c1c1c1;
$color-basic-2: #999999;
$color-basic-3: #111111;
$color-basic-4: #222222;
$color-basic-5: #cccccc;
$color-basic-6: #444444;

//2 font color
$font-color-1: #1d1d1d;
$font-color-2: #666666;
$font-color-3: #333333;
$font-color-4: #ebeaea;
$font-color-5: #696969;

//3 font-family & font-size
$font-family-1: "Poppins", -apple-system, BlinkMacSystemFont, Helvetica Neue,
  PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC,
  WenQuanYi Micro Hei, sans-serif;

$font-family-2: "Playball", -apple-system, BlinkMacSystemFont, Helvetica Neue,
  PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC,
  WenQuanYi Micro Hei, sans-serif;

$font-family-3: "-", -apple-system, BlinkMacSystemFont, Helvetica Neue,
  PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC,
  WenQuanYi Micro Hei, sans-serif;

$h1: 60px; // 特大号：顶拦标题，大按钮文字
$h2: 32px; // 大号：正文
$h3: 24px; // 中号：话题标题，人名，评论，大按钮文字，输入框，搜索条
$h4: 18px; // 小号：小按钮文字，地址，提示小子
$h5: 16px; // 特小号：次次级说明
$h6: 14px;

//4. padding
$space-1: 8px; // 特密
$space-2: 16px; // 亲密
$space-3: 24px; // 一般
$space-4: 32px; // 区分
$space-5: 40px;
$space-6: 48px;
$space-7: 56px;
$space-8: 64px;

$container-sm-max-width: 100%;
$container-md-max-width: 750px;
$container-lg-max-width: 970px;
$container-xl-max-width: 1170px;

$container-padding: $space-2;
$container-xs-padding: $space-2;

$body-background-color: white;
$body-font-size: $h6;
$body-font-family: $font-family-1;
$placeholderColor: $font-color-3;
$font-weight: 400;

$font-family-h1: $font-family-1;
$color-h1: $color-basic-3;
$line-height-h1: 1;
$letter-spacing-h1: 1;
$font-weight-h1: 600;
$margin-h1: 0 0 0;

$font-family-h2: $font-family-1;
$color-h2: $color-basic-3;
$line-height-h2: 1;
$letter-spacing-h2: 1;
$font-weight-h2: 600;
$margin-h2: 0 0 0;

$font-family-h3: $font-family-1;
$color-h3: $color-basic-3;
$line-height-h3: 1;
$letter-spacing-h3: 1;
$font-weight-h3: 500;
$margin-h3: 0 0 0;

$font-family-h4: $font-family-1;
$color-h4: $color-basic-3;
$line-height-h4: 1;
$letter-spacing-h4: 1;
$font-weight-h4: 400;
$margin-h4: 0 0 0;

$font-family-h5: $font-family-1;
$color-h5: $color-secondary-3;
$line-height-h5: 1;
$letter-spacing-h5: 1;
$font-weight-h5: 500;
$margin-h5: 0 0 0;

$font-family-h6: $font-family-1;
$color-h6: $color-secondary-3;
$line-height-h6: 1;
$letter-spacing-h6: 1;
$font-weight-h6: 400;
$margin-h6: 0 0 0;

$font-family-p: $font-family-1;
$color-p:$color-secondary-3;
$line-height-p: normal;
$letter-spacing-p: normal;
$font-weight-p: 400;
$margin-p: 0 0 0;
