@import "../module/stooges-variables";
@import "../module/stooges-mixin";
@import "./project-variables.scss";

/* Properties mixin */
%scrollBarProperties {
  ::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  ::-webkit-scrollbar-thumb {
    background: $font-color-2;
  }

  ::-webkit-scrollbar-track {
    background: #d6d6d6;
  }
}

%flexCenterRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

%flexBetweenRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

%flexCenterColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

%owlNavCenterBothSide {
  .owl-prev,
  .owl-next {
    position: absolute;
    border-radius: 50%;
    top: 50%;
    font-size: 32px;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background-color: white;
    transition: 0.3s;
    outline: none;
    &:hover {
      background-color: $color-main;
      span {
        color: white;
      }
    }
    span {
      @extend %flexCenterRow;
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
  }
  .owl-prev {
    left: 0;
  }
  .owl-next {
    right: 0;
  }
}

%contactForm {
  h2 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 25px;
    line-height: 1.2;
    color: black;
  }
  form {
    width: 100%;
    input {
      font-family: $font-family-1;
      height: 40px;
      border-radius: 2px;
      width: 100%;
      margin-bottom: 8px;
      padding: 0 10px;
      border: 1px solid $font-color-4;
      &::placeholder {
        color: $color-basic-2;
        font-weight: 400;
      }
    }
    textarea {
      font-family: $font-family-1;
      border-radius: 2px;
      width: 100%;
      max-height: 172px;
      padding: 10px;
      overflow: auto;
      resize: vertical;
      border: 1px solid $font-color-4;
      &::placeholder {
        color: $color-basic-2;
        font-weight: 400;
      }
    }
    button {
      font-size: 12px;
      background-color: $color-basic-4;
      height: 35px;
      padding: 0 20px;
      line-height: 35px;
      border: 0;
      border-radius: 0;
      margin-top: 8px;
      display: block;
      width: auto;
      cursor: pointer;
      color: white;
      text-transform: uppercase;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      outline: none;
      transition: 0.3s;
      &:hover {
        background-color: $color-main;
      }
    }
  }
}

%contactInfo {
  .description {
    p {
      font-size: 12px;
      line-height: 22px;
      margin-bottom: 10px;
      color: $font-color-2;
      font-family: $font-family-1;
    }
  }
  .item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid #f3f3f3;
    padding: 10px 0;
    &:nth-last-child(-n + 2) {
      border-bottom: none;
      padding: 2px 0;
    }
    i,
    h6 {
      color: $font-color-2;
      line-height: 22px;
      overflow: hidden;
      font-weight: 400;
      font-size: 12px;
    }
    i {
      font-size: 16px;
      margin-right: 8px;
    }
    h6 {
      a {
        color: $font-color-2;
        font-size: 12px;
        font-weight: 400;
        word-wrap: break-word;
        &:hover {
          color: $color-main;
        }
      }
    }
  }
}

%categoryBackgroundCover {
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(black, 0.6);
  height: 100%;
  width: 100%;
  z-index: 10;
  transition: 0.3s;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  &.active {
    width: calc(100% - 300px);
    visibility: visible;
    opacity: 1;
  }
}

%categoryBox {
  margin-bottom: 40px;
  background-color: white;
  .quitBtnWrapper {
    display: none;
    justify-content: flex-end;
    .quitBtn {
      cursor: pointer;
    }
  }
  .title {
    h3 {
      padding-bottom: 8px;
      color: $color-basic-3;
      font-size: 18px;
      font-weight: 600;
      font-family: $font-family-1;
    }
  }
  .lists {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        padding: 8px 0;
        border-bottom: 1px solid $color-secondary-2;
        &:last-child {
          border-bottom: none;
        }
        a {
          @extend %flexBetweenRow;
          &.active {
            p,
            span {
              font-weight: bold;
            }
          }
          &:hover,
          &.active {
            p,
            span {
              color: $color-main;
            }
          }
          p {
            color: $font-color-3;
          }
          span {
            color: $font-color-3;
          }
        }
      }
    }
  }
  @media #{$breakpoint-down-md} {
    overflow-y: auto;
    transition: 0.3s;
    padding: 16px 20px 0;
    position: fixed;
    width: 300px;
    height: 100%;
    z-index: 11;
    left: -300px;
    top: 0;
    &.active {
      left: 0;
    }
    .quitBtnWrapper {
      display: flex;
    }
    .title {
      margin-top: 8px;
    }
  }
}

%twoRowEllipsis {
  text-overflow: ellipsis;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

%productDisplayWithHoverEffect {
  position: relative;
  cursor: pointer;
  &:before {
    @extend %flexCenterRow;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    z-index: 2;
    transition: 0.3s;
    background-color: rgba(black, 0.2);
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
}
/* Properties mixin */

/* Component mixin */
%header {
  border-bottom: 1px solid $color-secondary-3;
  .topbar {
    background-color: $color-basic-3;
    height: 44px;
    .topbarWrapper {
      @extend %flexBetweenRow;
      .contactWrap {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        i {
          color: $color-basic-2;
          font-size: 12px;
          padding-right: 6px;
        }
        p {
          color: $color-basic-2;
          font-size: 12px;
          padding-right: 12px;
        }
        a {
          color: white;
          font-size: 14px;
          padding-right: 8px;
          transition: 0.3s;
          &:hover {
            color: $color-main;
          }
        }
      }
      .action {
        @extend %flexCenterRow;
        justify-content: flex-end;
        .searchInput {
          @extend %flexCenterRow;
          line-height: 44px;
          width: 50px;
          .searchButton {
            @extend %flexCenterRow;
            position: relative;
            cursor: pointer;
            .openButton {
              color: $color-basic-2;
              transition: 0.3s;
              font-size: 12px;
              &:hover {
                color: $color-main;
              }
            }
            .searchForm {
              @extend %flexCenterRow;
              justify-content: flex-start;
              position: absolute;
              transition: 0.3s;
              width: 250px;
              height: 100%;
              max-height: 0px;
              overflow: hidden;
              background-color: white;
              top: 46px;
              box-shadow: 0 2px 6px rgba(black, 0.15);
              border-radius: 5px;
              right: 0;
              z-index: 10;
              &.active {
                max-height: 100%;
              }
              input {
                position: relative;
                width: 100%;
                border: none;
                padding: 6px 16px;
                outline: none;
                color: $font-color-2;
                font-size: 14px;
                font-weight: 500;
                font-family: $font-family-1;
                line-height: 1.5;
                &::placeholder {
                  color: $font-color-2;
                }
              }
              button {
                position: relative;
                font-size: 14px;
                font-family: $font-family-1;
                line-height: 1.5;
                border: none;
                padding: 8px;
                cursor: pointer;
                background-color: transparent;
                i {
                  color: $font-color-2;
                }
              }
            }
          }
        }
        .state {
          @extend %flexCenterRow;
          line-height: 44px;
          cursor: pointer;
          &:before {
            content: "";
            height: 15px;
            width: 1px;
            margin-right: 8px;
            background-color: rgba(white, 0.2);
          }
          &:hover {
            i,
            p {
              color: $color-main;
            }
          }
          a {
            @extend %flexCenterRow;
            i {
              transition: 0.3s;
              color: $color-basic-2;
              font-size: 12px;
              margin-right: 4px;
            }
            p {
              transition: 0.3s;
              color: $color-basic-2;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .navigationBar {
    background-color: white;
    .website {
      @extend %flexCenterRow;
      justify-content: flex-start;
      padding: 16px 0;
      margin: 0 -16px;
      .logoWrapper {
        padding: 0 12px;
        a {
          width: 100%;
          display: block;
          img {
            display: flex;
            height: 53px;
          }
        }
      }
      .navigation {
        @extend %flexCenterRow;
        justify-content: flex-start;
        padding: 0 16px;
        height: 100%;
        nav {
          ul {
            @extend %flexCenterRow;
            justify-content: flex-start;
            list-style-type: none;
            padding: 0;
            margin: 0;
            li {
              @extend %flexCenterRow;
              padding: 16px 13px 17px;
              a {
                text-transform: uppercase;
                color: $font-color-1;
                font-weight: 600;
                font-size: 14px;
                line-height: 26px;
                font-family: $font-family-1;
                position: relative;
                display: block;
                transition: 0.4s;
                &:before {
                  content: "";
                  width: 0%;
                  height: 2px;
                  background: $color-main;
                  position: absolute;
                  bottom: -4px;
                  left: 10%;
                  transition: 0.4s;
                }
                &:hover,
                &.active {
                  color: $color-main;
                  &:before {
                    content: "";
                    width: 80%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .mobile {
      padding: 12px 0;
      border-top: 3px solid $color-basic-4;
      display: none;
      .navbar {
        @extend %flexBetweenRow;
        .logoWrapper {
          a {
            width: 55px;
            display: block;
            img {
              display: flex;
              height: 48px;
            }
          }
        }
        .mobileBarWrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
          .menuButton {
            @extend %flexCenterRow;
            width: 24px;
            height: 24px;
            font-size: 16px;
            color: $font-color-3;
            cursor: pointer;
            &:hover {
              color: $color-main;
            }
          }
          .searchInput {
            @extend %flexCenterRow;
            width: 100%;
            .openButton {
              @extend %flexCenterRow;
              height: 24px;
              width: 24px;
              cursor: pointer;
              i {
                font-size: 16px;
                color: $font-color-3;
                &:hover {
                  color: $color-main;
                }
              }
            }
            .searchForm {
              position: absolute;
              top: 100%;
              left: 0;
              width: 100%;
              z-index: 10;
              height: 0px;
              transition: 0.3s;
              overflow: hidden;
              background: #f5f5f5;
              &.active {
                height: 43px;
              }
              .formContainer {
                @extend %flexCenterRow;
                justify-content: flex-start;
                flex-wrap: nowrap;
                padding: 5px 15px;
                width: 100%;
                input {
                  padding: 6px 12px;
                  font-size: 14px;
                  line-height: 1.5;
                  border: none;
                  width: 100%;
                  font-family: $font-family-1;
                  font-weight: 400;
                  &::placeholder {
                    color: $font-color-2;
                  }
                }
                button {
                  border: none;
                  font-size: 14px;
                  line-height: 1.5;
                  padding: 6px 12px;
                  cursor: pointer;
                  font-family: $font-family-1;
                }
              }
            }
          }
          .backgroundCover {
            position: fixed;
            right: 0;
            top: 0;
            background: rgba(black, 0.6);
            height: 100%;
            width: 100%;
            z-index: 10;
            transition: 0.4s;
            visibility: hidden;
            opacity: 0;
            cursor: pointer;
            &.active {
              width: calc(100% - 300px);
              visibility: visible;
              opacity: 1;
            }
          }
          .mobileNavigation {
            background-color: #232528;
            position: fixed;
            left: -250px;
            top: 0;
            height: 100%;
            width: 300px;
            z-index: 80;
            transition: 0.4s;
            visibility: hidden;
            opacity: 0;
            &.active {
              left: 0;
              visibility: visible;
              opacity: 1;
            }
            nav {
              .logoContainer {
                background-color: white;
                position: relative;
                padding: 20px 10px 15px;
                .closeButton {
                  position: absolute;
                  top: 4px;
                  right: 8px;
                  .menuCloseButton {
                    padding: 2px 4px;
                    cursor: pointer;
                    i {
                      font-size: 8px;
                      color: $font-color-2;
                    }
                  }
                }
                a {
                  @extend %flexCenterRow;
                  img {
                    height: 32px;
                  }
                }
              }
              ul {
                @extend %flexCenterColumn;
                justify-content: flex-start;
                align-items: flex-start;
                list-style-type: none;
                padding: 0;
                margin: 0;
                width: 100%;
                padding-top: 20px;
                li {
                  width: 100%;
                  border-bottom: 1px solid #111112;
                  box-shadow: 0 1px 0 0 rgba(white, 0.1);
                  a {
                    @extend %flexCenterRow;
                    justify-content: flex-start;
                    transition: 0.3s;
                    padding: 5px 30px 5px 15px;
                    text-transform: uppercase;
                    color: white;
                    font-size: 13px;
                    line-height: 22px;
                    font-family: $font-family-1;
                    &:hover,
                    &.active {
                      color: $color-main;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media #{$breakpoint-down-sm} {
    .topbar {
      display: none;
    }
    .navigationBar {
      .website {
        display: none;
      }
      .mobile {
        display: block;
      }
    }
  }
  @media #{$breakpoint-down-md} {
    .navigationBar {
      .website {
        .navigation {
          nav {
            ul {
              li {
                padding: 8px;
              }
            }
          }
        }
      }
    }
  }
}

%singleImageCarousel {
  margin-bottom: 30px;
  .bannerCarousel {
    .item {
      width: 100%;
      position: relative;
      .bannerContainer {
        position: relative;
        overflow: hidden;
        padding: 0;
        display: block;
        width: 100%;
        &:before {
          content: "";
          display: block;
          padding-top: calc(11.35 / 30 * 100%);
        }
        img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 0;
          z-index: 0;
          object-fit: cover;
        }
        .bannerContent {
          @extend %flexCenterColumn;
          padding: 0 32px;
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          h1,
          h3 {
            color: white;
            text-align: center;
            span {
              color: $color-main;
              text-shadow: 1px 1px 1px #fff;
            }
          }
          .title {
            font-family: $font-family-2;
            font-style: italic;
            font-size: 36px;
            font-weight: 400;
            margin-bottom: 8px;
            line-height: 1.2;
          }
          .subTitle {
            font-size: 60px;
            line-height: 40px;
            margin-bottom: 20px;
            text-transform: uppercase;
            font-family: $font-family-1;
          }
          .description {
            margin-bottom: 16px;
            line-height: 1.2;
            font-size: 16px;
            font-family: $font-family-1;
            font-weight: 500;
          }
        }
      }
      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        background-color: rgba(black, 0.6);
        left: 0;
        top: 0;
      }
    }
    .owl-nav {
      @extend %owlNavCenterBothSide;
    }
  }
  @media #{$breakpoint-only-md} {
    .bannerCarousel {
      .item {
        .bannerContainer {
          .bannerContent {
            .title {
              font-size: 30px;
              margin-bottom: 8px;
              line-height: 1.2;
            }
            .subTitle {
              font-size: 40px;
              margin-bottom: 20px;
              line-height: 30px;
            }
            .description {
              font-size: 16px;
              margin-bottom: 16px;
              line-height: 1.2;
            }
          }
        }
      }
    }
  }
  @media #{$breakpoint-only-sm} {
    .bannerCarousel {
      .item {
        .bannerContainer {
          .bannerContent {
            .title {
              font-size: 16px;
              margin-bottom: 8px;
              line-height: 1.2;
            }
            .subTitle {
              font-size: 24px;
              line-height: 20px;
              margin-bottom: 20px;
            }
            .description {
              margin-bottom: 16px;
              line-height: 1.2;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  @media #{$breakpoint-only-xs} {
    .bannerCarousel {
      .item {
        .bannerContainer {
          .bannerContent {
            .title {
              font-size: 14px;
              margin-bottom: 8px;
              line-height: 1.2;
            }
            .subTitle {
              font-size: 14px;
              line-height: 16px;
              margin-bottom: 8px;
            }
            .description {
              margin-bottom: 4px;
              line-height: 1.2;
              font-size: 8px;
            }
          }
        }
      }
    }
  }
}

%carouselWithRoundedItemsWithinContainer {
  margin-bottom: 30px;
  .categoryCarousel {
    .slides {
      padding: 0 20px;
      &:hover {
        .imageWrapper {
          border: 1px solid $color-main;
          img {
            transform: scale(1.15);
          }
        }
        .categoryTitle {
          h4 {
            color: $color-main;
          }
        }
      }
      .imageWrapper {
        overflow: hidden;
        border: 1px solid $color-secondary-3;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        img {
          transition: 0.4s;
          object-fit: center;
        }
      }
      .categoryTitle {
        h4 {
          text-align: center;
          margin: 14px 0;
          color: $color-basic-3;
          font-size: 14px;
          line-height: 22px;
          font-family: $font-family-1;
          font-weight: 600;
          text-transform: uppercase;
          text-overflow: ellipsis;
          white-space: pre-wrap;
          word-wrap: break-word;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  @media #{$breakpoint-down-sm} {
    .categoryCarousel {
      .slides {
        padding: 0 5px;
      }
    }
  }
}

%threeImageInARowFullWidth {
  margin: 40px 0px 65px;
  display: flex;
  justify-content: flex-start;
  a {
    display: flex;
    flex: 1;
    .bannerWrapper {
      @extend %flexCenterRow;
      position: relative;
      overflow: hidden;
      margin-bottom: 16px;
      &:before {
        content: "";
        width: 0px;
        height: 0px;
        border-radius: 50%;
        opacity: 0;
        visibility: hidden;
        transition: 0.4s linear;
        position: absolute;
        border: 500px solid rgba(black, 0.9);
        cursor: pointer;
        z-index: 3;
      }
      &:hover {
        &:before {
          opacity: 1;
          visibility: visible;
          width: 1250px;
          height: 1250px;
        }
      }
      .imageDescription {
        @extend %flexCenterColumn;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        background: rgba(black, 0.5);
        width: 100%;
        height: 100%;
        padding: 0 16px;
        h2 {
          color: $color-main;

          font-size: 24px;
          text-transform: capitalize;
          line-height: 32px;
          text-align: center;
          text-shadow: 1px 1px 1px #fff
        }
        p {
          font-size: 16px;
          text-align: center;
          color: white;
          line-height: 24px;
        }
        span {
          @extend %flexCenterRow;
          margin-top: 24px;
          border-radius: 30px;
          height: 40px;
          padding: 0 30px;
          background: $color-main;
          color: white;
        }
      }
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
  @media #{$breakpoint-only-md} {
    a {
      .bannerWrapper {
        .imageDescription {
          h2 {
            font-size: 20px;
          }
          p {
            font-size: 12px;
            line-height: 18px;
          }
          span {
            margin-top: 16px;
            height: 32px;
            padding: 0 24px;
          }
        }
      }
    }
  }
  @media #{$breakpoint-down-sm} {
    flex-direction: column;
    a {
      .bannerWrapper {
        .imageDescription {
          p {
            font-size: 12px;
            text-align: center;
            color: white;
            line-height: 16px;
          }
          span {
            margin-top: 16px;
            border-radius: 30px;
            height: 32px;
            padding: 0 24px;
          }
        }
      }
    }
  }
}

%fourItemsDisplayWithTabsWithinContainer {
  .productsContainer {
    /*@extend %flexCenterColumn;*/
    .productDisplayTitle {
      text-align: center;
      h1 {
        text-transform: uppercase;
        font-family: $font-family-1;
        color: $color-basic-3;
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 13px;
        span {
          color: $color-main;
        }
      }
      h5 {
        color: $font-color-5;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 43px;
        font-family: $font-family-1;
      }
    }
    .productTabs {
      @extend %flexCenterRow;
    display: block;
    text-align: center;
      margin-bottom: 30px;
      width: 100%;
      .tabsContainer {
    display: inline-block;
        border: 1px solid $color-secondary-3;
        background: $color-secondary-2;
        margin: 0px 4px 10px;
        transition: 0.3s;
        border-radius: 30px;
        text-align: center;
        &:hover,
        &.active {
          background-color: $color-main;
          border-color: $color-main;
          a {
            h4 {
              color: white;
            }
          }
        }
        a {
          border-radius: 30px;
          display: block;
          padding: 13px 30px;
          h4 {
            border-radius: 30px;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 600;
            font-family: $font-family-1;
            color: $font-color-2;
            white-space: nowrap;
          }
        }
      }
      .mobileTabsContainer {
        display: none;
        position: relative;
        .mobileTabsButton {
          border: 1px solid $color-secondary-3;
          cursor: pointer;
          font-size: 20px;
          color: $color-basic-4;
          padding: 4px 8px;
          &:hover{
            .mobileTabs {
              display: block;
            }
          }
        }
        .mobileTabs {
          display: none;
          background-color: white;
          border: 1px solid $color-secondary-3;
          top: 100%;
          left: 0;
          z-index: 10;
          overflow: hidden;
          position: absolute;
          width: 180px;
          .tabSelection {
            transition: 0.3s;
            &:first-child {
              padding-top: 10px;
            }
            &:last-child {
              padding-bottom: 10px;
              h4 {
                border-bottom: 0px;
              }
            }
            &:hover,
            &.active {
              background-color: $color-secondary-2;
              a {
                h4 {
                  color: $color-main;
                }
              }
            }
            a {
              display: block;
              padding: 0 20px;
              h4 {
                border-bottom: 1px solid $color-secondary-3;
                padding: 12px 0;
                color: $color-basic-4;
                text-align: left;
                font-size: 14px;
                font-family: $font-family-1;
              }
            }
          }
        }
      }
    }
    .productsWrapper {
      margin-bottom: 10px;
      .products {
        display: block;
        border: 1px solid $color-secondary-3;
        .singleItem {
          @extend %productDisplayWithHoverEffect;
          img {
            width: 100%;
            height: 100%;
          }
          .text {
            padding: 16px 0;
            .itemTitle {
              @extend %flexCenterRow;
              @extend %twoRowEllipsis;
              font-weight: 400;
              line-height: 22px;
              font-size: 14px;
              width: 100%;
              height: 43px;
              color: $color-basic-6;
              font-family: $font-family-1;
              text-align: center;
            }
          }
        }
      }
    }
    .viewAllProductsButton {
      margin-bottom: 70px;
      .buttonContainer {
        border-radius: 30px;
        background-color: $color-main;
        border: 1px solid $color-main;
        text-align: center;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          background-color: $font-color-1;
          border: 1px solid $font-color-1;
        }
        a {
          display: block;
          height: 50px;
          border-radius: 30px;
          h4 {
            @extend %flexCenterRow;
            border-radius: 30px;
            height: 100%;
            color: white;
            font-family: $font-family-1;
            text-transform: uppercase;
            padding: 0 30px;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
  @media #{$breakpoint-down-lg} {
    .productsContainer {
      .productTabs {
        flex-wrap: wrap;
      }
    }
  }
  @media #{$breakpoint-only-md} {
    .productsContainer {
      .productsWrapper {
        .mainItem {
          &:nth-last-child(-n + 2) {
            display: none;
          }
        }
      }
    }
  }
  @media #{$breakpoint-only-sm} {
    .productsContainer {
      .productsWrapper {
        .mainItem {
          &:nth-last-child(-n + 4) {
            display: none;
          }
        }
      }
    }
  }
  @media #{$breakpoint-only-xs} {
    .productsContainer {
      .productTabs {
        justify-content: flex-start;
        .tabsContainer {
          display: none;
        }
        .mobileTabsContainer {
          display: block;
        }
      }
      .productsWrapper {
        .mainItem {
          &:nth-last-child(-n + 4) {
            display: none;
          }
        }
      }
    }
  }
}

%imageCarouselWithinContainer {
  padding: 95px 0;
  background-color: $color-secondary-2;
  &:hover {
    .brandingCarousel {
      .owl-nav {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .brandingCarousel {
    .slides {
      .imageWrapper {
        display: block;
        border: 1px solid transparent;
        transition: 0.4s;
        cursor: grab;
        &:hover {
          border-color: $color-main;
        }
        img {
          height: auto;
          width: 100%;
          display: block;
        }
      }
    }
    .owl-nav {
      @extend %owlNavCenterBothSide;
      transition: 0.3s;
      opacity: 0;
      visibility: hidden;
      .owl-prev {
        left: -45px;
      }
      .owl-next {
        right: -45px;
      }
    }
  }
  @media #{$breakpoint-down-sm} {
    padding: 35px 0;
  }
}

%floatingButton {
  position: fixed;
  right: 0;
  bottom: -40px;
  cursor: pointer;
  background-color: $color-main;
  line-height: 45px;
  z-index: 5;
  width: 40px;
  height: 40px;
  opacity: 0;
  transition: 0.3s;
  .contain {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    i {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      font-size: 14px;
      color: white;
      font-family: $font-family-1;
      animation: goyang 0.75s ease-in-out infinite alternate;
    }
  }
  &.active {
    bottom: 50px;
    opacity: 1;
  }
}

%footer {
  position: relative;
  padding: 50px 0 0;
  &:before {
    background-image: url("../../src/images/footer/1.jpg");
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: -2;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  &:after {
    background-color: rgba(black, 0.8);
    position: absolute;
    z-index: -1;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .about {
    margin-bottom: 40px;
    .contactTitle {
      margin-bottom: 24px;
      h2 {
        color: white;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.2;
        display: block;
        text-transform: uppercase;
        position: relative;
      }
    }
    .contactDetail {
      @extend %contactInfo;
      .item {
        padding: 10px 0;
        border-bottom: none;
        i,
        h6 {
          color: $color-secondary-3;
        }
        i {
          font-size: 16px;
        }
        h6 {
          font-size: 14px;
          a {
            display: block;
            font-size: 14px;
            color: $color-secondary-3;
            &:hover {
              color: $color-main;
            }
          }
        }
        .call {
          a {
            color: $color-main;

            font-size: 20px;
            line-height: 20px;
            &:hover {
              color: $color-main;
            }
          }
        }
      }
    }
  }
  .contact {
    @extend %flexCenterColumn;
    align-items: flex-start;
    margin-bottom: 40px;
    h2 {
      margin-bottom: 24px;
      color: white;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.2;
      display: block;
      text-transform: uppercase;
      position: relative;
    }
    a {
      font-size: 14px;
      font-weight: 400;
      padding: 3px 0;
      color: $color-secondary-3;
      line-height: 26px;
      display: block;
      text-transform: uppercase;
      font-family: $font-family-1;
      &:hover {
        color: $color-main;
      }
    }
  }
  .contactForm {
    @extend %contactForm;
    h2 {
      margin-bottom: 34px;
      color: white;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.2;
      display: block;
      text-transform: uppercase;
      position: relative;
    }
    form {
      input,
      textarea {
        background-color: rgba(white, 0.1);
        border-color: rgba($font-color-4, 0.1);
        color: white;
        outline: none;
      }
    }
  }
  .copyright {
    @extend %flexBetweenRow;
    padding: 24px 0;
    p {
      a {
        color: $color-main;

        &:hover {
          color: lighten($color-main, 25);
        }
      }
    }
  }
  @media #{$breakpoint-down-sm} {
    .copyright {
      padding: 16px 0 2px;
      p {
        font-size: 14px;
      }
    }
  }
  @media #{$breakpoint-down-xs} {
    .copyright {
      p {
        font-size: 12px;
      }
    }
  }
}

%displayParagraphWithTitleWithinContainer {
  .head {
    background-color: $color-main;
    padding: 40px 0;
    .title {
      font-weight: 500;
      color: white;
      font-size: 25px;
      line-height: 35px;
    }
    .subTitle {
      margin: 0 0 10px;
      font-size: 16px;
      line-height: 24px;
      color: white;
    }
  }
  .policyDetail {
    padding: 30px 0;
    h1,
    h2,
    h3,
    h4 {
      margin-bottom: 30px;
      font-size: 30px;
      font-weight: 500;
    }
    p {
      margin-bottom: 30px;
      font-size: 16px;
      font-weight: 400;
      color: $font-color-3;
    }
    ul {
      margin-bottom: 30px;
      li {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

%horizontalWordDisplayWithArrowWithinContainer {
  .directory {
    @extend %flexCenterRow;
    justify-content: flex-start;
    padding: 35px 0;
    span {
      padding-right: 16px;
      h6 {
        text-transform: uppercase;
        font-family: $font-family-1;
        font-size: 14px;
        font-weight: 600;
        color: $color-secondary-1;
        a {
          color: $color-secondary-1;
        }
      }
    }
  }
}

%threeItemsDisplayInARowWithCarouselAndSidebarWithinContainer {
  .productSidebar {
    .sidebarButtonContainer {
      display: none;
      .sidebarButton {
        @extend %flexCenterRow;
        justify-content: flex-start;
        background-color: $font-color-2;
        padding: 2px 10px;
        margin-bottom: 20px;
        cursor: pointer;
        i,
        h6 {
          color: white;
          font-size: 13px;
          font-weight: 500;
          text-transform: uppercase;
        }
        i {
          margin-right: 5px;
        }
      }
    }
    .categoryBackgroundCover {
      @extend %categoryBackgroundCover;
    }
    .categoryBox {
      @extend %categoryBox;
    }
  }
  .productsDisplay {
    .title {
      margin-bottom: 16px;
      h1 {
        font-weight: 700;
        font-size: 28px;
        font-family: $font-family-1;
        text-transform: uppercase;
        color: $color-basic-3;
      }
    }
    .productsWrapper {
      .products {
        a {
          display: block;
          .item {
            @extend %productDisplayWithHoverEffect;
            border: 1px solid $color-secondary-3;
            .imageContainer {
              img {
                width: 100%;
              }
            }
            .title {
              padding: 16px 0;
              margin: 0;
              h4 {
                @extend %twoRowEllipsis;
                @extend %flexCenterRow;
                height: 43px;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: $color-basic-3;
                font-family: $font-family-1;
              }
            }
          }
        }
      }
    }
    .partsButton {
      margin-top: 24px;
      h1 {
        font-weight: 700;
        font-size: 28px;
        font-family: $font-family-1;
        text-transform: uppercase;
        color: $color-basic-3;
      }
      .partsCarousel {
        margin: 20px 0;
        @extend %flexCenterRow;
        justify-content: flex-start;
        position: relative;
        &:hover {
          .owl-nav {
            .owl-prev,
            .owl-next {
              opacity: 1;
            }
          }
        }
        .slides {
          padding: 0 10px;
          &:hover {
            .imageWrapper {
              img {
                transform: scale(1);
              }
            }
          }
          a {
            .imageWrapper {
              border-radius: 0;
              border: none;
            }
            .categoryTitle {
              padding: 10px 0;
              text-align: center;
              h4 {
                font-size: 14px;
                line-height: 1.4;
                text-transform: uppercase;
                color: $font-color-2;
                font-weight: 500;
                padding: 0;
                &:last-child {
                  text-transform: none;
                }
              }
            }
          }
        }
        .owl-nav {
          .owl-prev,
          .owl-next {
            color: white;
            position: absolute;
            background-color: rgba($color-basic-1, 0.8);
            font-size: 32px;
            opacity: 0;
            transition: 0.3s;
            width: 30px;
            height: 30px;
            text-align: center;
            outline: none;
            span {
              display: block;
              height: 30px;
              width: 30px;
              line-height: 30px;
            }
          }
          .owl-prev {
            left: -15px;
          }
          .owl-next {
            right: -15px;
          }
        }
      }
    }
  }
  @media #{$breakpoint-down-md} {
    .productSidebar {
      .sidebarButtonContainer {
        display: flex;
      }
    }
  }
}

%viewItemsImageInCarouselWithItemDetailAndCarouselAndSidebarWithinContainer {
  margin-bottom: 40px;
  .productSidebar {
    .sidebarButtonContainer {
      display: none;
      .sidebarButton {
        @extend %flexCenterRow;
        justify-content: flex-start;
        background-color: $font-color-2;
        padding: 2px 10px;
        margin-bottom: 20px;
        cursor: pointer;
        i,
        h6 {
          color: white;
          font-size: 13px;
          font-weight: 500;
          text-transform: uppercase;
        }
        i {
          margin-right: 5px;
        }
      }
    }
    .categoryBackgroundCover {
      @extend %categoryBackgroundCover;
    }
    .categoryBox {
      @extend %categoryBox;
    }
  }
  .itemDetail {
    .imagePreview {
      .owl-stage-outer {
        border: 1px solid $color-secondary-3;
        .item {
          width: 100%;
          .imageContainer {
            position: relative;
            display: block;
            width: 100%;
            padding: 0;
            overflow: hidden;
            &:before {
              content: "";
              display: block;
              padding-top: calc(4 / 5 * 100%);
            }
            img {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border: 0;
              object-fit: cover;
            }
          }
        }
      }
      .owl-dots {
        @extend %flexBetweenRow;
        width: 100%;
        overflow: hidden;
        margin-top: 16px;
        .owl-dot {
          padding-right: 8px;
          &:last-child {
            padding-right: 0px;
          }
          &.active,
          &:hover {
            .imageDots {
              border-color: $color-main;
            }
          }
          .imageDots {
            @extend %flexCenterRow;
            background-color: white;
            outline: none;
            border: 1px solid $color-secondary-3;
            height: 100%;
            width: 100%;
            transition: 0.3s;
            padding: 0;
            cursor: pointer;
            .owlImage {
              background-size: cover;
              transition: 0.2s;
              pointer-events: none;
              max-height: 100px;
              width: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
    .itemDetails {
      .itemName {
        margin-bottom: 16px;
        h1 {
          font-weight: 600;
          font-size: 32px;
          color: $color-basic-3;
          display: block;
        }
      }
      .itemDescription {
        h4 {
          display: block;
          color: $font-color-2;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 16px;
          font-family: $font-family-1;
          span {
            color: $color-basic-4;
            padding-right: 5px;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
          }
        }
      }
      .contactMethod {
        @extend %flexCenterRow;
        justify-content: flex-start;
        margin-bottom: 8px;
        .whatsapp,
        .callUsNow {
          margin-top: 80px;
          margin-right: 10px;
          a {
            padding: 0 20px;
            border-radius: 30px;
            color: white;
            font-size: 14px;
            font-family: $font-family-1;
            font-weight: 500;
            text-transform: uppercase;
            line-height: 32px;
            text-align: center;
            white-space: nowrap;
            display: block;
            transition: 0.3s;
          }
        }
        .whatsapp {
          a {
            background-color: #2bb240;
            &:hover {
              background-color: darken(#2bb240, 10);
            }
          }
        }
        .callUsNow {
          a {
            background-color: $color-main;
            &:hover {
              background-color: darken($color-main, 10);
            }
          }
        }
      }
    }
    .suggestedItem {
      margin-top: 40px;
      position: relative;
      h3 {
        color: $font-color-1;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 30px;
      }
      .suggestedCarousel {
        .slides {
          a {
            @extend %productDisplayWithHoverEffect;
            display: block;
            text-align: center;
            border: 1px solid $color-secondary-3;
            .imageWrapper {
              img {
                width: 100%;
                height: 100%;
              }
            }
            .productName {
              padding: 16px 0;
              h4 {
                @extend %twoRowEllipsis;
                @extend %flexCenterRow;
                text-align: center;
                line-height: 22px;
                height: 43px;
                font-weight: 400;
                margin: 0;
                font-size: 14px;
                color: $color-basic-3;
                font-family: $font-family-1;
              }
            }
          }
        }
        .owl-nav {
          .owl-prev,
          .owl-next {
            color: white;
            position: absolute;
            top: -46px;
            background-color: $color-basic-1;
            font-size: 32px;
            transition: 0.3s;
            width: 30px;
            height: 30px;
            text-align: center;
            outline: none;
            span {
              display: block;
              height: 30px;
              width: 30px;
              line-height: 30px;
            }
            &:hover {
              background-color: $color-main;
            }
          }
          .owl-prev {
            right: 32px;
          }
          .owl-next {
            right: 0px;
          }
        }
      }
    }
  }
  @media #{$breakpoint-down-md} {
    .productSidebar {
      .sidebarButtonContainer {
        display: flex;
      }
    }
    .itemDetail {
      .imagePreview,
      .itemDetails {
        padding: 0;
      }
      .itemDetails {
        margin-top: 20px;
        .itemName {
          h1 {
            font-size: 24px;
          }
        }
        .itemDescription {
          h4 {
            font-size: 14px;
            span {
              font-size: 14px;
            }
          }
        }
        .contactMethod {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .whatsapp,
          .callUsNow {
            margin-top: 16px;
          }
        }
      }
    }
  }
}

%imageWithParagraphInARowWithinContainer {
  padding-bottom: 30px;
  .title {
    h2 {
      text-transform: uppercase;
      color: $color-basic-6;
      margin-bottom: 30px;
      position: relative;
      font-weight: 700;
      font-size: 20px;
      display: block;
      &:before {
        content: "";
        background-color: $color-main;
        display: inline-block;
        position: absolute;
        bottom: -8px;
        width: 70px;
        height: 3px;
        left: 0;
      }
    }
  }
  .imageContainer {
    margin-bottom: 20px;
    position: relative;
    /* &:hover {
      &:before {
        content: "";
        position: absolute;
        background-color: rgba(white, 0.35);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
      }
    } */
    img {
      width: 100%;
      height: 100%;
    }
  }
  .aboutDescription {
    .aboutTitle {
      margin-bottom: 24px;
      h2 {
        font-weight: 400;
        color: $color-basic-4;
        font-size: 28px;
      }
    }
    .aboutDetail {
      margin-bottom: 35px;
      p {
        color: $font-color-2;
        font-size: 14px;
        margin-bottom: 16px;
        font-family: $font-family-1;
      }
    }
  }
  .contactButton {
    display: flex;
    a {
      @extend %flexCenterRow;
      text-align: center;
      position: relative;
      border-radius: 30px;
      background-color: #737373;
      padding: 0 35px;
      height: 35px;
      transition: 0.3s;
      &:hover {
        background-color: $color-main;
      }
      .buttonDesign {
        h4 {
          border: none;
          color: white;
          font-size: 14px;
        }
      }
    }
  }
}

%threePointsWithDescriptionAndIconWithinContainer {
  padding: 30px 0;
  .pointDetail {
    @extend %flexCenterColumn;
    text-align: center;
    margin-bottom: 24px;
    .topIcon {
      i {
        @extend %flexCenterRow;
        border-radius: 50%;
        background-color: $color-main;
        font-size: 36px;
        width: 100px;
        height: 100px;
        color: white;
      }
    }
    .pointTitle {
      padding: 20px 0 10px;
      h2 {
        font-size: 14px;
        font-weight: 700;
        color: $color-basic-4;
        text-transform: uppercase;
      }
    }
    .pointDescription {
      p {
        color: $font-color-2;
        font-size: 14px;
        font-family: $font-family-1;
      }
    }
  }
}

%displayAnIFrameWithBorderWithinContainer {
    margin-bottom: 40px;

    .title {
        h2 {
            text-transform: uppercase;
            color: $color-basic-6;
            margin-bottom: 30px;
            position: relative;
            font-weight: 700;
            font-size: 20px;
            display: block;

            &:before {
                content: "";
                background-color: $color-main;
                display: inline-block;
                position: absolute;
                bottom: -8px;
                width: 70px;
                height: 3px;
                left: 0;
            }
        }
    }

    .mapContainer {
        .googleMap {
            border: 5px solid $color-secondary-3;
            height: 616px;
        }
    }

    @media #{$breakpoint-down-sm} {
        .mapContainer {
            .googleMap {
                height: 316px;
            }
        }
    }
}

%contactInfoWithEnquiryFormInARowWithinContainer {
  margin-bottom: 30px;
  .contactInfo {
    .contactTitle {
      margin-bottom: 24px;
      h2 {
        color: black;
        font-weight: 600;
        font-size: 20px;
        line-height: 1;
        text-transform: uppercase;
      }
    }
    .contactDetail {
      @extend %contactInfo;
    }
  }
  .contactForm {
    @extend %contactForm;
  }
}

%displayParagraphWithTitleWithinContainer {
  margin-bottom: 35px;
  .titleContainer {
    margin-bottom: 30px;
    position: relative;
    h1 {
      font-size: 22px;
      color: $color-basic-4;
      font-weight: 700;
      line-height: 1.2;
      text-transform: uppercase;
    }
    &:before {
      content: "";
      width: 70px;
      height: 3px;
      display: inline-block;
      position: absolute;
      bottom: -8px;
      left: 0;
      background: $color-main;
    }
  }
  .services {
    margin: 12px 0;
    border-bottom: 1px solid $font-color-4;
    &:last-child {
      border-bottom: none;
    }
    .title {
      margin-bottom: 8px;
      h2 {
        font-size: 28px;
        color: $color-basic-3;
        font-weight: 400;
        line-height: 1.2;
      }
    }
    .description {
      p {
        margin-bottom: 16px;
        font-size: 14px;
        font-family: $font-family-1;
        color: $color-basic-3;
        line-height: 22px;
      }
    }
  }
}
/* Component mixin */
